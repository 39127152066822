import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import textService from './textService'

const initialState = {
  texts: [],
  email_template: null,
  home_texts: [],
  header_texts: [],
  footer_texts: [],
  misc_texts: [],
  all_texts: [],
  text: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get texts
export const getTexts = createAsyncThunk('texts/all', async (thunkAPI) => {
  try {
    return await textService.getTexts()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get misc texts
export const getMiscTexts = createAsyncThunk('texts/misc', async (thunkAPI) => {
  try {
    return await textService.getTextByCategory("misc")
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})


// Get home texts
export const getHomeTexts = createAsyncThunk('texts/home', async (thunkAPI) => {
  try {
    return await textService.getTextByCategory("home-page")
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get header texts
export const getHeaderTexts = createAsyncThunk('texts/header', async (thunkAPI) => {
  try {
    return await textService.getTextByCategory("header")
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get header texts
export const getFooterTexts = createAsyncThunk('texts/footer', async (thunkAPI) => {
  try {
    return await textService.getTextByCategory("footer")
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Add text
export const addText = createAsyncThunk('texts/add', async (text, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await textService.addText(token, text)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Edit text
export const editText = createAsyncThunk('texts/edit', async (text, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await textService.editText(token, text)
  } catch (error) {
    const message =

      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getEmailTemplate = createAsyncThunk('texts/email', async (thunkAPI) => {
  try {
    return await textService.getEmailTemplate()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateEmailTemplate = createAsyncThunk('texts/updateEmail', async (email_text, thunkAPI) => {
  try {
    return await textService.updateEmailTemplate(email_text)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const textSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.all_texts = action.payload
      })
      .addCase(getTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.all_texts = []
      })
      .addCase(addText.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addText.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.all_texts = [...state.all_texts, action.payload]
      })
      .addCase(addText.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editText.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editText.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.all_texts = action.payload
      })
      .addCase(editText.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getHomeTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getHomeTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.home_texts = action.payload
      })
      .addCase(getHomeTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getHeaderTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getHeaderTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.header_texts = action.payload
      })
      .addCase(getHeaderTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getFooterTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getFooterTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.footer_texts = action.payload
      })
      .addCase(getFooterTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getMiscTexts.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMiscTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.misc_texts = action.payload
      })
      .addCase(getMiscTexts.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getEmailTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEmailTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.email_template = action.payload
      })
      .addCase(getEmailTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateEmailTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateEmailTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.email_template = action.payload
      })
      .addCase(updateEmailTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      
  },
})

export const { reset } = textSlice.actions
export default textSlice.reducer